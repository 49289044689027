import React, { useState, useMemo, useCallback, useContext } from "react";
import { GetAllCampaigns, ResendSms } from "../../API";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../component/newTable";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import ResendPopUp from "../../component/ResendPopUp";
import CampaignDetailsPopUp from "../../component/CampaignDetailsPopUp";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import "./style.css";

export default function AllCampaigns() {
  const [reload, setReload] = useState(false);
  const { reloadCredit, toggleCredit } = useContext(Context);
  const { startDate, endDate } = useContext(Context);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const { setCampaignDetailsPopUp, setResendPopUp } = useContext(Context);
  const [campaignID, setcampaignID] = useState(null);
  const [smsCount, setsmsCount] = useState(null);
  const [toggleSideBar] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const showCampaignDetails = (id) => {
    setcampaignID(id);
    setCampaignDetailsPopUp(true);
  };

  const showResend = (id, sms) => {
    setcampaignID(id);
    setsmsCount(sms);
    setResendPopUp(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: t("date"),
        accessor: "SendDate",
      },
      {
        Header: t("time"),
        accessor: "SendTime",
      },
      {
        Header: t("campaignName"),
        accessor: "CampaignName",
        Cell: ({ row: { original } }) => {
          return (
            <div
              className="viewDetailsStyle"
              onClick={() => navigate(`/DLRTable/${original.ID}`)}
            >
              {original.CampaignName}
            </div>
          );
        },
      },
      {
        Header: t("count"),
        accessor: "RowsCount",
      },
      {
        Header: t("Vodafone"),
        accessor: "VodafoneCount",
      },
      {
        Header: t("Orange"),
        accessor: "OrangeCount",
      },
      {
        Header: t("Etisalat"),
        accessor: "EtisalatCount",
      },
      {
        Header: t("We"),
        accessor: "WECount",
      },
      {
        Header: t("sender"),
        accessor: "Sender",
      },
      {
        Header: t("batchCost"),
        accessor: "ReqCost",
      },
      {
        Header: t("campaignDetails"),
        accessor: "",
        Cell: ({ row: { original } }) => {
          return (
            <div
              className="viewDetailsStyle"
              onClick={() => showCampaignDetails(original.ID)}
            >
              {t("viewDetails")}
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "ReSend",
        Cell: ({ row: { original } }) => {
          if (original.fileName !== 'no file uploaded') {
            return (
              <div>
                <button
                  className="exportBtn"
                  onClick={() => showResend(original.ID, original.RowsCount)}
                  disabled={isLoadingBtn}
                >
                  {t("Resend")}
                  {isLoadingBtn && (
                    <span className="spinner-border" role="status"></span>
                  )}
                </button>
              </div>
            );
          } else {
            return null;
          }
        },
      }
    ],
    [t, reload, reloadCredit]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetAllCampaigns({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate)
      })
        .then((response) => {
          setIsLoading(false);

          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [t, reload, reloadCredit, startDate, endDate]
  );

  return (
    <div className="scheduleContainer">
      <Header
        title={t("AllCampaigns")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <DateRangePicker />
      </div>

      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("AllCampaigns")}
        />
      </div>

      <CampaignDetailsPopUp campaignID={campaignID} />
      <ResendPopUp campaignID={campaignID} smsCount={smsCount} />
    </div>
  );
}
